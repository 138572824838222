<template>
  <div class="shortMessage">
    <div ref="search" class="search">
      <el-card class="box-card" shadow="never" :body-style="{padding:'10px'}">
        <el-form :inline="true" :model="searchForm" label-width="80px">
          <el-form-item label="账户名称">
            <el-input v-model="searchForm.name" :size="$store.state.size" placeholder="账户名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="账户ID">
            <el-input v-model="searchForm.id" :size="$store.state.size" placeholder="账户ID" clearable></el-input>
          </el-form-item>
          <el-form-item label="账户状态">
            <el-select v-model="searchForm.type" :size="$store.state.size" placeholder="客户类型" clearable>
              <el-option label="启用" value="1"></el-option>
              <el-option label="停用" value="2"></el-option>
              <el-option label="测试" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="测试手机号" label-width="100px">
            <el-input v-model="searchForm.testPhone" :size="$store.state.size" placeholder="测试手机号"
                      clearable></el-input>
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-button v-if="hasPermission('2-4-a')" type="primary" :size="$store.state.size" @click="searchSubmit" icon="el-icon-search">查询</el-button>
        <el-button v-if="hasPermission('2-4-b')" type="default" plain :size="$store.state.size" @click="resetSubmit" icon="el-icon-delete">清空
        </el-button>
        <el-button v-if="hasPermission('2-4-c')" type="primary" :size="$store.state.size" @click="saveOpen" icon="el-icon-circle-plus-outline">新增
        </el-button>
      </el-card>
    </div>
    <el-table
        :data="tableData"
        :height="tableHeight"
        border
        :size="this.$common.tableLineHeight()"
        :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}"
        style="width: 100%; margin-top: 10px">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50">
      </el-table-column>
      <el-table-column
          prop="name"
          align="center"
          label="账户名称">
      </el-table-column>
      <el-table-column
          prop="id"
          align="center"
          label="账户ID">
      </el-table-column>
      <el-table-column
          prop="passWord"
          align="center"
          label="账户密码">
      </el-table-column>
      <el-table-column
          prop="type"
          align="center"
          label="账户状态">
        <template slot-scope="scope">
          {{ scope.row.type === '1' ? scope.row.type === '2' ? '停用' : '启用' : '测试' }}
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="测试手机号">
        <template slot-scope="scope">
          <span :key="index" v-for="(item,index) of scope.row.testPhone">
            {{ item}}{{scope.row.testPhone.length-1 === index? '':','}}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="操作"
          width="260">
        <template slot-scope="scope">
          <el-button v-if="hasPermission('2-4-d')" type="text" size="small" @click="seeOpen(scope.row)" icon="el-icon-document">查看</el-button>
          <el-button v-if="hasPermission('2-4-e')" type="text" size="small" @click="editOpen(scope.row)" icon="el-icon-edit-outline">编辑</el-button>
          <el-button v-if="hasPermission('2-4-f')" type="text" size="small" @click="delOpen(scope.row)" icon="el-icon-delete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
        :current-page="currentPage"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange">
    </pagination>


    <!--    保存模态框-->
    <assembly-dialog
        title="保存"
        :visible.sync="dialogVisibleSave"
        width="30%"
        @before-close="handleClose">
      <el-form :model="saveForm" :rules="saveRules" ref="saveForm" label-width="80px">
        <el-form-item label="账户名称" prop="name">
          <el-input v-model="saveForm.name" :size="$store.state.size" placeholder="账户名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="账户ID" prop="id">
          <el-input v-model="saveForm.id" :size="$store.state.size" placeholder="账户ID" clearable></el-input>
        </el-form-item>
        <el-form-item label="账户密码" prop="passWord">
          <el-input v-model="saveForm.passWord" :size="$store.state.size" placeholder="账户密码" clearable></el-input>
        </el-form-item>
        <el-form-item label="账户状态" prop="type">
          <el-select v-model="saveForm.type" :size="$store.state.size" placeholder="客户类型" clearable>
            <el-option label="启用" value="1"></el-option>
            <el-option label="停用" value="2"></el-option>
            <el-option label="测试" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="测试电话" prop="testPhone">
          <el-input v-model="saveForm.testPhone" :size="$store.state.size" placeholder="测试电话" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :size="$store.state.size" @click="clearForm('saveForm')">清 空</el-button>
        <el-button :size="$store.state.size" type="primary" @click="saveSubmit('saveForm')">确 定</el-button>
      </span>
    </assembly-dialog>
    <!--    查看模态框-->
    <assembly-dialog
        title="查看"
        :visible.sync="dialogVisibleSee"
        width="30%"
        @before-close="seeClose"
    >
      <el-descriptions class="margin-top" :column="1" :size="$store.state.size" border>
        <el-descriptions-item>
          <template slot="label">
            账户名称
          </template>
          {{ seeForm.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            账户ID
          </template>
          {{ seeForm.id }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            账户密码
          </template>
          {{ seeForm.passWord }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            账户状态
          </template>
          {{ seeForm.type === '1' ? seeForm.type === '2' ? '停用' : '启用' : '测试' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            测试电话
          </template>
          <span :key="index" v-for="(item,index) of seeForm.testPhone">
            {{ item}}{{seeForm.testPhone.length-1 === index? '':','}}
          </span>
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button :size="$store.state.size" @click="seeClose">关 闭</el-button>
      </span>
    </assembly-dialog>
  </div>
</template>

<script>
import pagination from "@/components/assembly-pagination/index";
import AssemblyDialog from "components/assembly-dialog";

export default {
  name: "shortMessage",
  components:{
    AssemblyDialog,
    pagination
  },
  watch: {
    //监听头部展开收起变化
    '$store.state.isHeader'() {
      this.setTableHeight()
    },
    //监听快速导航展开收起变化
    '$store.state.fastNav'() {
      this.setTableHeight()
    },
    //监听搜索区域变化改变列表
    searchShow() {
      this.setTableHeight()
    }
  },
  data() {
    return {
      //搜索区域展开收起开关
      searchShow: false,
      //搜索区域展开收起图标
      searchOpenIcon: 'el-icon-arrow-down',
      //表格高度
      tableHeight: 0,
      //表格数据
      tableData: [
        {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }, {
          name: '账户1',
          id: '001',
          passWord: '1q@w3e4r',
          type: '1',
          testPhone: ['13000000000', '15800000000']
        }],
      //当前页面
      currentPage: 1,
      //最大页数
      total:'',
      //搜索表单
      searchForm: {
        goodsPiCustomerId:'',
        goodsPiName: '',
        id: '',
        passWord: '',
        type: '',
        testPhone: ''
      },
      //保存模态框开关
      dialogVisibleSave: false,
      //保存表单
      saveForm: {
        goodsPiName: '',
        id: '',
        passWord: '',
        type: '',
        testPhone: ''
      },
      //保存表单验证
      saveRules: {
        name: [
          {required: true, validator: this.$verification.messageName, trigger: 'blur'}
        ],
        id: [
          {required: true, message: '请输入账户ID', trigger: 'blur'}
        ],
        passWord: [
          {required: true, validator: this.$verification.messagePassWord, trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择账户状态', trigger: 'blur'}
        ],
        testPhone: [
          {required: true, validator: this.$verification.personnelTel, trigger: 'blur'}
        ]
      },
      //查看模态框开关
      dialogVisibleSee: false,
      seeForm: {
        name: '',
        id: '',
        passWord: '',
        type: '',
        testPhone: ''
      },
    }
  },
  mounted() {
    //初始化计算表格区域高度
    this.setTableHeight();
  },
  methods: {
    //判断权限是否存在
    hasPermission(item){
      return this.$common.hasPermission(item);
    },
    /****表格高度计算 start********************************************************************************/
    //计算表格区域高度
    setTableHeight() {
      this.$nextTick(() => {
        this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
        window.onresize = () => {
          return (() => {
            this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
          })();
        };
      })
    },
    /****表格高度计算 end********************************************************************************/
    /****搜素区域 start********************************************************************************/
    //搜索展开收起事件
    searchOpen() {
      this.searchShow = !this.searchShow
      this.searchOpenIcon = this.searchShow? 'el-icon-arrow-up':'el-icon-arrow-down'
    },
    //搜索表单提交
    searchSubmit() {
      this.$alert(this.searchForm, '请求数据', {
        confirmButtonText: '确定'
      });
    },
    //搜索表单重置
    resetSubmit() {
      this.$common.clearFormValue(this.searchForm)
      this.$alert(this.searchForm, '请求数据', {
        confirmButtonText: '确定'
      });
    },
    /****搜素区域 end********************************************************************************/
    /****保存区域 start********************************************************************************/
    //保存表单提交
    saveOpen() {
      this.dialogVisibleSave = true
    },
    //模态框关闭前回调
    handleClose() {
        this.dialogVisibleSave = false
        this.clearForm('saveForm')
    },
    //保存提交
    saveSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
          this.dialogVisibleSave = false
          this.$common.clearFormValue(this.saveForm)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //清空表单
    clearForm(formName) {
      this.$refs[formName].resetFields();
      this.$common.clearFormValue(this.saveForm)
    },
    //编辑模态框开启
    editOpen(row) {
      this.saveForm = JSON.parse(JSON.stringify(row))
      this.dialogVisibleSave = true
    },
    /****保存区域 end********************************************************************************/
    /****查看区域 start********************************************************************************/
    seeOpen(row) {
      this.seeForm = row
      this.dialogVisibleSee = true
    },
    seeClose() {
      this.dialogVisibleSee = false
    },
    /****查看区域 end********************************************************************************/
    /****删除区域 start********************************************************************************/
    delOpen(row) {
      this.$confirm('确认删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    /****删除区域 end********************************************************************************/
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    onSubmit() {
      console.log('submit!');
    }
  },
}
</script>

<style lang="scss">
.shortMessage {
  .search {
    //搜索展开收起按钮位置设置
    .searchOpenBtn {
      float: right;
    }

    //表单每行底部外边距设置
    .el-form-item {
      margin-bottom: 10px;
    }

    .el-select {
      width: 200px;
    }

    .el-input {
      width: 200px;
    }

    //分割线外边距设置
    .el-divider--horizontal {
      margin: 0 0 10px 0;
    }
  }

  .el-dialog {
    .el-select {
      width: 100%;
    }

    .el-input {
      width: 100%;
    }
  }
}
</style>
